import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import { Container } from './split-box.css';

const Animated = posed.div({
  preEnterToRight: {
    x: 'calc(-50vw + 3.5rem)',
  },
  preEnterToLeft: {
    x: 'calc(50vw - 3.5rem)',
  },
  enterToLeft: {
    applyAtStart: { borderLeft: '1px solid' },
    applyAtEnd: { borderLeft: 'none' },
    x: '0vw',
    transition: {
      duration: 600,
      ease: 'easeInOut',
    },
  },
  enterToRight: {
    applyAtStart: { borderRight: '1px solid' },
    x: '0vw',
    transition: {
      duration: 600,
      ease: 'easeInOut',
    },
  },
});

const getDirection = (side, pose) => {
  switch (side) {
    case 'left':
      return pose + 'ToRight';
    case 'right':
      return pose + 'ToLeft';
    default:
      return pose + 'ToRight';
  }
};

const SplitBox = ({ children, width, background, side, location }) => (
  <Animated
    className={`w-full border-box ${side === 'left' &&
      'hidden md:block border-r border-solid'}`}
    initialPose={
      location.state && location.state.direction === 'toBoth'
        ? getDirection(side, 'preEnter')
        : null
    }
    pose={
      location.state && location.state.direction === 'toBoth'
        ? getDirection(side, 'enter')
        : null
    }
  >
    <Container
      width={width}
      bg={background}
      side={side === 'left' ? 'pl-14 border-r' : 'px-9 md:pr-14 md:pl-0'}
    >
      {children}
    </Container>
  </Animated>
);

SplitBox.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  background: PropTypes.string,
  side: PropTypes.string,
  location: PropTypes.object,
};

export default SplitBox;
